import { Theme } from './index'
import { getEnv } from './helpers'
import verlindeLogo from './logos/verlinde.svg'
import whiteVerlindeLogo from './logos/verlinde-white.svg'
import healthAppImage from './images/verlinde-health-app.png'
import qrAppStore from './images/verlinde-appstore-qr.jpg'
import qrGooglePlay from './images/verlinde-googleplay-qr.jpg'
import bannerDesktop from './images/verlinde-banner-desktop.png'
import bannerTablet from './images/verlinde-banner-tablet.png'
import bannerMobile from './images/verlinde-banner-mobile.png'
import { getStoreUrls } from './helpers'
import { getBrandedModule } from './defaultModules'

const isProduction = getEnv() === 'prod'

const colors = {
  green: '#008D5C',
  grey: '#4B5457',
  darkGreen: '#005737',
  darkerGreen: '#003B25',
  white: '#FFFFFF',
  black: '#000000',
  button: {
    color: '#008D5C',
    hover: '#005737',
    disabled: '#DDDDDD',
  },
  text: {
    body: '#000000',
    headings: '#48535B',
    link: '#008D5C',
    linkHover: '#003B25',
  },
  monochrome: {
    darkestGrey: '#282B2E',
    darkerGrey: '#5B5B5B',
    darkGrey: '#777777',
    mediumGrey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    lighterGrey: '#F2F2F2',
    lightestGrey: '#F8F8F8',
  },
}

const primaryTile = {
  color: colors.text.body,
  backgroundColor: colors.monochrome.lightestGrey,
  hoverBackgroundColor: colors.monochrome.lightGrey,
  iconColor: colors.text.headings,
}

const externalTile = {
  color: colors.white,
  backgroundColor: colors.green,
  hoverBackgroundColor: colors.darkGreen,
}

const stagemakerTile = {
  color: colors.white,
  backgroundColor: colors.text.headings,
  hoverBackgroundColor: colors.black,
}

const demoTile = {
  ...primaryTile,
  backgroundColor: colors.monochrome.lighterGrey,
  demoBackgroundColor: colors.text.headings,
}

const smallIcon = {
  style: {
    fontSize: '3em',
  },
}

const storeUrls = getStoreUrls('https://store.verlinde.com/ver/en')
const warrantyUrl = 'https://verlinde-ext-prod.launchpad.cfapps.eu20.hana.ondemand.com/site/fiori'

const themeConfig: Theme = {
  brand: 'verlinde',
  portalName: 'MyVerlinde',
  privacyPolicyLink:
    'https://www.konecranes.com/data-protection/customer-contact-data-protection-description',
  cookiePolicyLink: 'https://www.konecranes.com/data-protection-cookie-statement',
  companyLink: 'https://www.verlinde.com',
  siteTermsLink: '/terms-and-conditions/alpha-portal/latest.default.html',
  fontFamily: 'Ropa Sans',
  fontSize: '18px',
  headerLogo: verlindeLogo,
  menuLogo: verlindeLogo,
  footerLogo: whiteVerlindeLogo,
  footerCopyrightId: 'verlinde_copyright',
  headerLogoSize: '240px',
  menuLogoSize: '240px',
  footerLogoSize: '180px',
  footerBackgroundColor: colors.text.headings,
  primary: colors.green,
  pageHeaderColor: colors.text.headings,
  headerBackgroundColor: colors.monochrome.darkestGrey,
  linkColor: colors.text.link,
  linkHoverColor: colors.text.linkHover,
  dashboardBanner: {
    desktopImage: bannerDesktop,
    tabletImage: bannerTablet,
    mobileImage: bannerMobile,
    borderColor: colors.monochrome.lightestGrey,
  },
  healthApp: {
    qrAppStore,
    qrGooglePlay,
    image: healthAppImage,
    appStoreUrl: 'https://apps.apple.com/fi/app/expertcare/id1492450592',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.verlinde.expertcare',
    moreInfoUrl: 'https://www.verlinde.com/en/news/expertcare-verlinde.html',
  },
  header: {
    color: colors.grey,
    backgroundColor: colors.monochrome.darkestGrey,
  },
  menuLink: {
    color: colors.monochrome.darkestGrey,
    hoverColor: colors.green,
    hoverBackgroundColor: colors.monochrome.lightestGrey,
  },
  listItem: {
    titleColor: colors.monochrome.darkestGrey,
    descriptionColor: colors.monochrome.darkGrey,
  },
  button: {
    primary: {
      color: colors.button.color,
      textColor: colors.white,
      hover: colors.button.hover,
    },
    secondary: {
      color: colors.button.color,
      hover: colors.button.hover,
    },
  },
  registerUri: {
    storeDomain: 'https://store.verlinde.com',
    storeAbbr:'ver'
  },
  newsModal: {
    tagBackground: colors.monochrome.mediumGrey
  },
  modules: [
    // Main modules below.
    getBrandedModule('activationFlow'),
    getBrandedModule('store', {
      to: storeUrls.storeUrl,
      external: true,
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '5em',
        },
      },
    }),
    getBrandedModule('chainSystem', {
      title: 'expert_lift',
      showExternalIcon: true,
      tileProps: externalTile,
      tileIconProps: {
        width: '5em',
      },
    }),
    getBrandedModule('news', {
      tileProps: externalTile,
    }),
    getBrandedModule('documents', {
      to: storeUrls.documentsUrl,
      title: 'equipment_specific_documents',
      description: '',
      secondary: true,
      external: true,
      tileProps: externalTile,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('deliveryTracker', {
      to: storeUrls.deliveryTrackerUrl,
      tooltip: 'verlinde_delivery_tracker_tooltip',
      secondary: true,
      external: true,
      tileProps: externalTile,
    }),
    getBrandedModule('businessDashboard', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('eLearning', {
      tileProps: externalTile,
      secondary: true,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('leadTimes', {
      tileProps: externalTile,
      secondary: true,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('serviceDocuments', {
      title: 'information_documents',
      tileProps: externalTile,
      secondary: true,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('troubleshooting', {
      tileProps: externalTile,
      secondary: true,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('warranty', {
      to: warrantyUrl,
      description: 'place_warranty_notifications',
      secondary: true,
      external: true,
      tileProps: externalTile,
    }),
    ...(!isProduction
      ? [
        getBrandedModule('assetFleet', {
          secondary: true,
          tileProps: primaryTile,
          title: 'verlinde_remote_monitoring',
          menuItemTitle: 'verlinde_remote_monitoring',
        }),
        getBrandedModule('monitoredAsset', {
          title: 'verlinde_remote_monitoring',
          menuItemTitle: 'verlinde_remote_monitoring',
        }),
      ]
      : []),
    getBrandedModule('myPartnersAndEquipment', {
      secondary: true,
      hideDescription: true,
      tileProps: primaryTile,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('marketingBank', {
      to: 'https://www.verlinde.com/ressources/',
      title: 'verlinde_marketing_bank',
      description: 'verlinde_marketing_bank_description',
      secondary: true,
      external: true,
      tileProps: primaryTile,
      tileIconProps: smallIcon,
    }),
    getBrandedModule('dashboard'),
    getBrandedModule('contact'),
    getBrandedModule('verlindeCom', {
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('stagemaker', {
      secondary: true,
      tileProps: stagemakerTile,
    }),
    getBrandedModule('priceList', {
      secondary: true,
      external: true,
      tileProps: primaryTile,
    }),
    // Demo modules below.
    getBrandedModule('trainingCalendar', {
      secondary: true,
      demo: true,
      tileProps: demoTile,
      tileIconProps: smallIcon,
    }),
    // Demo modules below.
    ...(isProduction
      ? [
        getBrandedModule('remoteDataDemo', {
          secondary: true,
          demo: true,
          tileProps: demoTile,
          tileIconProps: smallIcon
        }),
      ]
      : []),
    getBrandedModule('generalPriceList', {
      to: '/pricelists/Verlinde-2024-Tarif-rev-July-2024.pdf',
      tooltip: '',
      secondary: true,
      external: true,
      tileProps: externalTile,
    }),
    getBrandedModule('stagemakerPriceList', {
      to: '/pricelists/Stagemaker-2024-Tarif-rev-July-2024.pdf',
      tooltip: '',
      secondary: true,
      external: true,
      tileProps: externalTile,
    }),
    getBrandedModule('healthApp', {
      title: 'expert_care',
      secondary: true,
      tileProps: primaryTile,
    }),
    getBrandedModule('hotjarDecrypt', {
      secondary: true,
      tileProps: primaryTile,
    }),
  ],
}

export function loadTheme(): void {
  loadFontAndIcon()
  setFontSize()
  updatePageTitle()
}

// FIXME: this better be done w/ react-helmet.
function loadFontAndIcon() {
  const head = document.getElementsByTagName('head')[0]
  const fontLinkEl = document.createElement('link')
  fontLinkEl.rel = 'stylesheet'
  fontLinkEl.type = 'text/css'
  fontLinkEl.href = 'https://fonts.googleapis.com/css?family=Ropa+Sans&display=swap'
  head.appendChild(fontLinkEl)

  const iconLinkEl = document.createElement('link')
  iconLinkEl.rel = 'icon'
  iconLinkEl.type = 'image/x-icon'
  iconLinkEl.href = './icons/verlinde.ico'
  head.insertBefore(iconLinkEl, head.firstChild)
}

function setFontSize() {
  document.documentElement.style.fontSize = themeConfig.fontSize!
}

function updatePageTitle() {
  const title = document.getElementsByTagName('title')[0]

  if (title) title.textContent = themeConfig.portalName!
}

export default themeConfig
