import { FeatureNames } from '../api/types'

// NOTE: 'Customers' missing from here.
const FEATURES: { [key: string]: FeatureNames } = {
  ASSET_FLEET: 'AssetFleet',
  CONTACT_INFO: 'ContactInfo',
  DOCUMENTS_AND_MANUALS: 'DocumentsAndManuals',
  OFFER_REQUEST: 'OfferRequest',
  STORE_ACCESS: 'StoreAccess',
  SUBSCRIPTION_MANAGEMENT: 'SubscriptionManagement',
  TROUBLESHOOTING: 'Troubleshooting',
  USER_MANAGEMENT: 'UserManagement',
  COURSES: 'Courses',
  SERVICE_DOCUMENTS: 'ServiceDocuments',
  TRAINING_AREA: 'TrainingArea',
  CAMPAIGNS: 'Campaigns',
  CUSTOMERS: 'Customers:List',
  BUSINESS_DASHBOARD: 'BusinessDashboard',
  NEWS: 'News',
  HOTJAR: 'Hotjar',
  REMOTE_MONITORING: 'RemoteMonitoring',
  LABELS: 'Labels',
  LEAD_TIME: 'LeadTime'
}

export default FEATURES
