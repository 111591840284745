import axios, { AxiosInstance, AxiosResponse } from 'axios'

import { FiltersAsParams } from '../pages/AssetsPage/types'
import {
  CustomerId,
  Customer,
  Asset,
  Label,
  Contact,
  Keys,
  Course,
  UploadMetadata,
  CourseSignedUrl,
  Document,
  DocumentSignedUrl,
  Video,
  VideoMetadata,
  Crane,
  News,
  NewsMetadata,
  PowerBIKeys,
  Permissions,
  ChainSystemSSO,
  LiveTraining,
  LiveTrainingMetadata,
  EncryptedEmail,
  DecryptedEmail,
  ActiveEquipment,
  MissingOrder
} from './types'

type APIConfig = {
  baseUrl: string
  apiVersion: string
  getAccessTokenSilently: () => Promise<string>
}

export const operations = ({ title, description, url, languages, publishedAt, showToAll, name, language, countryCodes, schedule }:any
) => {
  const result = []
  if (title) result.push({ op: 'replace', path: '/title', value: title })
  if (description) result.push({ op: 'replace', path: '/description', value: description })
  if (url) result.push({ op: 'replace', path: '/url', value: url })
  if (languages) result.push({ op: 'replace', path: '/languages', value: languages })
  if (publishedAt) result.push({ op: 'replace', path: '/publishedAt', value: publishedAt })
  if (typeof showToAll !== 'undefined') result.push({ op: 'replace', path: '/showToAll', value: showToAll })
  if (description === '') result.push({ op: 'remove', path: '/description' })
  if (name) result.push({ op: 'replace', path: '/name', value: name })
  if (language) result.push({ op: 'replace', path: '/language', value: language })
  if (countryCodes) result.push({ op: 'replace', path: '/countryCodes', value: countryCodes })
  if (schedule) result.push({ op: 'replace', path: '/schedule', value: schedule })
  return result
}

export default class APIClient {
  // Public mainly for mocks.
  public readonly axios: AxiosInstance

  constructor(config: APIConfig) {
    const { baseUrl, apiVersion, getAccessTokenSilently } = config

    // Create an axios instance so we don't have to repeat the params.
    // This also makes it a lot easier to mock.
    this.axios = axios.create({
      baseURL: `${baseUrl}/${apiVersion}`,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
      },
    })

    this.axios.interceptors.request.use(async (req) => {
      const accessToken = await getAccessTokenSilently()
      return {
        ...req,
        headers: {
          ...req.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    })

    // Always log error on failure.
    this.axios.interceptors.response.use(undefined, (error) => {
      // Ignore test error codes to keep the test log clean.
      if (error.response.status !== 599) {
        // istanbul ignore next
        console.error('[APIClient]', error.response)
      }
      return Promise.reject(error)
    })
  }

  public getCustomerId(): Promise<AxiosResponse<CustomerId>> {
    return this.axios.get<CustomerId>('/customer-id')
  }

  public listCustomers(
    limit: number,
    offset: number,
    direction: string,
    filters: FiltersAsParams,
  ): Promise<AxiosResponse<Customer[]>> {
    return this.axios.get<Customer[]>('/customers', {
      params: {
        limit,
        offset,
        orderBy: `${direction}(accountname)`,
        ...filters,
      },
    })
  }

  public getCustomerById(customerId: string): Promise<AxiosResponse<Customer[]>> {
    return this.axios.get<Customer[]>(`/customers/${customerId}`)
  }

  // Not in use
  public listLocationsByCustomerId(customerId: string): Promise<AxiosResponse<Location[]>> {
    return this.axios.get<Location[]>(`/customers/${customerId}/locations`)
  }

  public getLocationById(customerId: string, locationId: number): Promise<AxiosResponse<Location>> {
    return this.axios.get<Location>(`/customers/${customerId}/locations/${locationId}`)
  }

  public listAssets(
    customerId: string,
    limit: number,
    offset: number,
    direction: string,
    filters: FiltersAsParams,
  ): Promise<AxiosResponse<Asset[]>> {
    return this.axios.get<Asset[]>(`/customers/${customerId}/assets`, {
      params: {
        limit,
        offset,
        orderBy: `${direction}(salesordernumber)`,
        ...filters,
      },
    })
  }

  // Not in use
  public listAssetsById(customerId: string, assetId: string): Promise<AxiosResponse<Asset[]>> {
    return this.axios.get<Asset[]>(`/customers/${customerId}/assets/${assetId}`)
  }

  public listAssetsBySerialNumber(
    customerId: string,
    serialNumber: string,
  ): Promise<AxiosResponse<Asset[]>> {
    return this.axios.get<Asset[]>(`/customers/${customerId}/assets`, {
      params: {
        serialNumber,
      },
    })
  }

  public listAssetsByOrderNo(customerId: string, orderNo: string): Promise<AxiosResponse<Asset[]>> {
    return this.axios.get<Asset[]>(`/customers/${customerId}/orders/${orderNo}`)
  }

  public listAssetsByInternalOrderNo(
    customerId: string,
    internalSalesOrderNo: string,
  ): Promise<AxiosResponse<Asset[]>> {
    return this.axios.get<Asset[]>(`/customers/${customerId}/assets`, {
      params: {
        internalSalesOrderNo,
      },
    })
  }

  public listLabelsByCustomerId(customerId: string, category: string) {
    return this.axios.get<Label[]>(`/customers/${customerId}/labels`, {
      params: {
        category,
        label: '*', // We don't use the server-side search and fetch all.
      },
    })
  }

  public listLabelsByAssetId(customerId: string, assetId: string): Promise<AxiosResponse<Label[]>> {
    return this.axios.get<Label[]>(`/customers/${customerId}/assets/${assetId}/labels`)
  }

  public addLabelToAsset(
    customerId: string,
    assetId: string,
    label: { label: string; category: string },
  ): Promise<AxiosResponse<Label>> {
    return this.axios.post<Label>(`/customers/${customerId}/assets/${assetId}/labels`, {
      ...label,
    })
  }

  public deleteLabelFromAsset(
    customerId: string,
    assetId: string,
    labelId: string,
  ): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/customers/${customerId}/assets/${assetId}/labels/${labelId}`)
  }

  public listContacts(title?: string): Promise<AxiosResponse<Contact[]>> {
    return this.axios.get<Contact[]>(`/contacts`, {
      params: {
        title,
      },
    })
  }

  public addContact(contact: Contact): Promise<AxiosResponse<Contact>> {
    const { title, name, description, category, country, address, email, phone } = contact
    return this.axios.post<Contact>(`/contacts`, {
      title,
      name,
      description,
      category,
      country,
      address,
      email,
      phone,
    })
  }

  public updateContact(contact: Contact): Promise<AxiosResponse<Contact>> {
    const { id, title, name, description, category, country, address, email, phone } = contact
    return this.axios.put<Contact>(`/contacts/${id}`, {
      title,
      name,
      description,
      category,
      country,
      address,
      email,
      phone,
    })
  }

  // Returns an empty string, though shouldn't return anything.
  public deleteContact(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/contacts/${id}`)
  }

  public getPermissions(): Promise<AxiosResponse<Permissions>> {
    return this.axios.get<Permissions>('/permissions')
  }

  public getKeys(): Promise<AxiosResponse<Keys>> {
    return this.axios.get<Keys>('/keys')
  }

  public getPowerBIKeys(): Promise<AxiosResponse<PowerBIKeys>> {
    return this.axios.get<PowerBIKeys>('/keys/pbi')
  }

  public getChainSystemSSO(): Promise<AxiosResponse<ChainSystemSSO>> {
    return this.axios.get<ChainSystemSSO>('/chainsystem/login')
  }

  public listCourses(): Promise<AxiosResponse<Course[]>> {
    return this.axios.get<Course[]>('/courses')
  }

  public getCourseUploadSignedUrl(
    course: UploadMetadata,
  ): Promise<AxiosResponse<CourseSignedUrl>> {
    return this.axios.post<CourseSignedUrl>('/courses', course)
  }

  public updateCourse(course: Course): Promise<AxiosResponse<Course>> {
    // TODO: proper typing
    const { id } = course
    return this.axios.patch<Course>(`/courses/${id}`, operations(course))
  }

  public deleteCourse(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/courses/${id}`)
  }

  public listDocuments(): Promise<AxiosResponse<Document[]>> {
    return this.axios.get<Document[]>('/documents')
  }

  public getDocumentUploadSignedUrl(
    course: UploadMetadata,
  ): Promise<AxiosResponse<DocumentSignedUrl>> {
    return this.axios.post<DocumentSignedUrl>('/documents', course)
  }


  public updateDocument(document: Document): Promise<AxiosResponse<Document>> {
    // TODO: proper typing
    const { id } = document
    return this.axios.patch<Document>(`/documents/${id}`, operations(document))
  }

  public deleteDocument(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/documents/${id}`)
  }

  public getDocumentDownloadSignedUrl(
    id: string
  ): Promise<AxiosResponse<{downloadUrl: string}>> {
    return this.axios.get<{ downloadUrl: string }>(`/documents/${id}`, )
  }

  public listServiceDocuments(): Promise<AxiosResponse<Document[]>> {
    return this.axios.get<Document[]>('/service-documents')
  }

  public getServiceDocumentUploadSignedUrl(
    course: UploadMetadata,
  ): Promise<AxiosResponse<DocumentSignedUrl>> {
    return this.axios.post<DocumentSignedUrl>('/service-documents', course)
  }

  public getServiceDocumentDownloadSignedUrl(
    id: string
  ): Promise<AxiosResponse<{downloadUrl: string}>> {
    return this.axios.get<{ downloadUrl: string }>(`/service-documents/${id}`, )
  }

  public updateServiceDocument(document: Document): Promise<AxiosResponse<Document>> {
    // TODO: proper typing
    const { id } = document
    return this.axios.patch<Document>(`/service-documents/${id}`, operations(document))
  }

  public deleteServiceDocument(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/service-documents/${id}`)
  }

  public listTrainingAreaDocuments(): Promise<AxiosResponse<Document[]>> {
    return this.axios.get<Document[]>('/training-area')
  }

  public getTrainingAreaDocumentUploadSignedUrl(
    course: UploadMetadata,
  ): Promise<AxiosResponse<DocumentSignedUrl>> {
    return this.axios.post<DocumentSignedUrl>('/training-area', course)
  }

  public getTrainingAreaDocumentDownloadSignedUrl(
    id: string
  ): Promise<AxiosResponse<{downloadUrl: string}>> {
    return this.axios.get<{ downloadUrl: string }>(`/training-area/${id}`, )
  }

  public updateTrainingAreaDocument(document: Document): Promise<AxiosResponse<Document>> {
    // TODO: proper typing
    const { id } = document
    return this.axios.patch<Document>(`/training-area/${id}`, operations(document))
  }

  public deleteTrainingAreaDocument(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/training-area/${id}`)
  }

  public listCampaigns(): Promise<AxiosResponse<Document[]>> {
    return this.axios.get<Document[]>('/campaigns')
  }

  public getCampaignUploadSignedUrl(
    course: UploadMetadata,
  ): Promise<AxiosResponse<DocumentSignedUrl>> {
    return this.axios.post<DocumentSignedUrl>('/campaigns', course)
  }

  public getCampaignDownloadSignedUrl(
    id: string
  ): Promise<AxiosResponse<{downloadUrl: string}>> {
    return this.axios.get<{ downloadUrl: string }>(`/campaigns/${id}`, )
  }

  public updateCampaign(document: Document): Promise<AxiosResponse<Document>> {
    // TODO: proper typing
    const { id } = document
    return this.axios.patch<Document>(`/campaigns/${id}`, operations(document))
  }

  public deleteCampaign(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/campaigns/${id}`)
  }

  public listVideos(): Promise<AxiosResponse<Video[]>> {
    return this.axios.get<Video[]>('/videos')
  }

  public createVideo(video: VideoMetadata): Promise<AxiosResponse<Video>> {
    return this.axios.post<Video>('/videos', video)
  }

  public updateVideo(video: Video): Promise<AxiosResponse<Video>> {
    const { id } = video
    return this.axios.patch<Video>(`/videos/${id}`, operations(video))
  }

  public deleteVideo(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/videos/${id}`)
  }

  public listLiveTrainings(): Promise<AxiosResponse<LiveTraining[]>> {
    return this.axios.get<LiveTraining[]>('/live-trainings')
  }

  public createLiveTraining(liveTraining: LiveTrainingMetadata): Promise<AxiosResponse<LiveTraining>> {
    return this.axios.post<LiveTraining>('/live-trainings', liveTraining)
  }


  public updateLiveTraining(liveTraining: LiveTraining): Promise<AxiosResponse<LiveTraining>> {
    const { id } = liveTraining
    return this.axios.patch<LiveTraining>(`/live-trainings/${id}`, operations(liveTraining))
  }

  public deleteLiveTraining(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/live-trainings/${id}`)
  }

  public listNews(): Promise<AxiosResponse<News[]>> {
    return this.axios.get<News[]>('/news')
  }

  public createNews(news: NewsMetadata): Promise<AxiosResponse<News>> {
    return this.axios.post<News>('/news', news)
  }

  public updateNews(news: News): Promise<AxiosResponse<News>> {
    const { id } = news
    return this.axios.patch<News>(`/news/${id}`, operations(news))
  }

  public deleteNews(id: string): Promise<AxiosResponse<string>> {
    return this.axios.delete<string>(`/news/${id}`)
  }

  public decryptEmail(encryptedEmail: EncryptedEmail): Promise<AxiosResponse<DecryptedEmail>> {
    return this.axios.post<DecryptedEmail>('/hj', encryptedEmail)
  }

  public getHoistsForConditionMonitoringUnit(conditionMonitoringUnitSerialNumber: string): Promise<AxiosResponse<ActiveEquipment>> {
    return this.axios.get<any>(`/active-equipments/${conditionMonitoringUnitSerialNumber}`)
  }

  public getCrane(datalakeId: string): Promise<AxiosResponse<Crane>> {
    return this.axios.get<any>(`/cranes/${datalakeId}`)
  }

  public getLeadTime(
    product: string,
    defaultFactory: string,
    deliveryRange: string,
    features: { [key: string]: string },
  ): Promise<AxiosResponse<string>> {
    const featureParams = Object.entries(features).reduce(
      (acc: { [key: string]: string }, [key, value]) => {
        acc[`feature.${key}`] = value
        return acc
      },
      {},
    )

    return this.axios.get<string>('/lead-time', {
      params: {
        product,
        defaultFactory,
        deliveryRange,
        ...featureParams,
      },
    })
  }

  public activateCrane(crane: Crane): Promise<AxiosResponse<Crane>> {
    return this.axios.post<Crane>('/cranes', crane)
  }

  public updateLeadTimes(leadTimes: string): Promise<AxiosResponse<{ leadTimes: string }>> {
    return this.axios.post<{ leadTimes: string }>('/lead-times', { leadTimes })
  }

  public getLeadTimes(): Promise<AxiosResponse<any>> {
    return this.axios.get('/lead-times', )
  }

  public getActiveCranes(): Promise<AxiosResponse<Crane[]>> {
    return this.axios.get<any>('/cranes')
  }

  public getActiveEquipments(): Promise<AxiosResponse<ActiveEquipment[]>> {
    return this.axios.get<any>(`/active-equipments`)
  }

  public createMissingOrder(missingOrder: MissingOrder): Promise<AxiosResponse<string>>{
    return this.axios.post<string>('/missing-orders', missingOrder)
  }
}

export const testAPIConfig: APIConfig = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  apiVersion: process.env.REACT_APP_API_VERSION,
  getAccessTokenSilently: async () => 'test_access_token',
}
